.ant-notification {
  z-index: 999999999999 !important;
}

.ant-table-thead > tr > th {
  color: #2d3748;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  text-align: left;
  background: #f1f5f8;
  border-bottom: 1px solid transparent;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-table-tbody {
  background: #f1f5f8;
}
.ant-table-row {
  background: #fff;
}
.ant-table-tbody > tr > td {
  border-bottom: solid 6px #f1f5f8;
  background: transparent;
}
.ant-pagination-item {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
}
.ant-pagination-item-active {
  background: #fff;
  font-weight: 600;
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0px 3px 20px #0000000b;
}
.ant-pagination-item-active a {
  color: #000;
}

.ant-pagination-next {
  background-color: transparent;
  border: none;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border: none;
  background: transparent;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border: none;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #000;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  font-weight: 500;
}
.ant-btn-primary {
  border-radius: 5px;
}
.ant-upload-list-item-card-actions {
  display: none;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.04);
  height: 380px;
  overflow-y: auto;
}
.chat::-webkit-scrollbar {
  width: 5px;
}
.chat::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.chat::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all ease 0.3s;
}
.chat::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
.chat .chat-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.chat .chat-message.chat-me {
  margin-left: auto;
}
.chat .chat-message.chat-me .chat-message-body {
  background-color: var(--primary);
  color: #fff;
}
.chat .chat-message .chat-message-body {
  padding: 15px;
  background-color: #383a3f;
  min-width: 280px;
  max-width: 450px;
  margin-right: 10px;
  color: #fff;
  border-radius: 12px;
}
.chat .chat-message .chat-message-time {
  font-style: italic;
  padding-top: 15px;
}
